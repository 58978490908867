<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="ประวัติการใช้"
        :filter="filter"
        @search="handleSearch"
        @clear="clearFilter"
      >
        <template v-slot:filter-option>
          <b-col>
            <InputText
              v-model="filter.billId"
              textFloat="หมายเลขบิล (POS Ref No.)"
              placeholder="หมายเลขบิล (POS Ref No.)"
              type="text"
              name="billId"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.name"
              textFloat="ชื่อสมาชิก (Member Name)"
              placeholder="ชื่อสมาชิก (Member Name)"
              type="text"
              name="name"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.telephone"
              textFloat="เบอร์โทรศัพท์ (Telephone)"
              placeholder="เบอร์โทรศัพท์ (Telephone)"
              type="text"
              name="telephone"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="สาขา (Branch)"
              isRequired
              :options="branchList"
              v-model="filter.branchId"
              @onDataChange="val => (filter.branchId = val)"
              textField="name"
              valueField="id"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันที่ใช้ (Start Date)"
              name="fromDate"
              ref="fromDate"
              v-model="filter.fromDate"
              @input="changeFromDate"
              :v="$v.filter.fromDate"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันสิ้นสุดการใช้ (End Date)"
              name="toDate"
              ref="toDate"
              v-model="filter.toDate"
              @input="onChangeToDate"
              :v="$v.filter.toDate"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputSelect
              title="Single Treatment"
              isRequired
              :options="singleList"
              v-model="filter.singleId"
              @onDataChange="val => (filter.singleId = val)"
              textField="name"
              valueField="id"
              class="mb-2"
            />
          </b-col>
        </template>
      </HeaderPanel>
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
            >
              <template v-slot:cell(used)="data">
                <div>{{ data.item.used | numeral('0,0') }}</div>
              </template>
              <template v-slot:cell(price)="data">
                <div>{{ data.item.price | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(package_price)="data">
                <div>{{ data.item.package_price | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(TreatmentValue)="data">
                <div>{{ data.item.TreatmentValue | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(original_burn_income)="data">
                <div>
                  {{ data.item.original_burn_income | numeral('0,0.00') }}
                </div>
              </template>
              <template v-slot:cell(burn_income)="data">
                <div>{{ data.item.burn_income | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(buy_time)="data">
                <div>
                  {{
                    $moment(data.item.buy_time, 'MM/DD/YYYY HH:mm:ss')
                      .add(543, 'years')
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:cell(created_time)="data">
                <div>
                  {{
                    $moment(data.item.created_time, 'MM/DD/YYYY HH:mm:ss')
                      .add(543, 'years')
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from '@/components/loading/OtherLoading';
import HeaderPanel from '@/components/HeaderPanel';
import Pagination from '@/components/Pagination';
import InputSelect from '@/components/inputs/InputSelect';
import InputText from '@/components/inputs/InputText';
import InputDatePickerFilter from '@/components/inputs/InputDatePickerFilter';
import { mapGetters } from 'vuex';

import { minValue, maxValue } from 'vuelidate/lib/validators';

const minDate = (value, fromDate) => {
  if (!value) return true;
  let minDate = fromDate;
  return new Date(value) >= new Date(minDate);
};
const maxDate = (value, toDate = null) => {
  let maxDate = toDate || new Date();
  return new Date(maxDate) >= new Date(value);
};

export default {
  components: {
    OtherLoading,
    Pagination,
    HeaderPanel,
    InputSelect,
    InputText,
    InputDatePickerFilter
  },
  name: 'ReportSpaUsageHistory',
  data() {
    return {
      fields: [
        {
          key: 'member_id',
          label: 'รหัสสมาชิก'
        },
        {
          key: 'first_name_th',
          label: 'ชื่อ'
        },
        {
          key: 'last_name_th',
          label: 'นามสกุล'
        },
        {
          key: 'telephone',
          label: 'เบอร์โทร'
        },
        {
          key: 'invoice_no',
          label: 'Bill Ref.'
        },
        {
          key: 'category',
          label: 'ประเภท'
        },
        {
          key: 'type',
          label: 'ชื่อแพ็คเกจ/เครดิต'
        },
        {
          key: 'name',
          label: 'รายการ',
          tdClass: 'text-left'
        },
        {
          key: 'buy_time',
          label: 'วันที่ซื้อ'
        },
        {
          key: 'buy_branch_name',
          label: 'สาขาที่ซื้อ'
        },
        {
          key: 'created_time',
          label: 'วันที่ใช้บริการ'
        },
        {
          key: 'use_branch_name',
          label: 'สาขาที่ใช้'
        },
        {
          key: 'status',
          label: 'สถานะ'
        },
        {
          key: 'price',
          label: 'ราคาขาย'
        },
        {
          key: 'package_price',
          label: 'ราคาขายต่อแพ็คเกจ'
        },
        {
          key: 'used',
          label: 'ครั้งที่ใช้/ทั้งหมด'
        },
        {
          key: 'TreatmentValue',
          label: 'Treatment Value'
        },
        {
          key: 'original_burn_income',
          label: 'Original Burn Income'
        },
        {
          key: 'burn_income',
          label: 'Burn Income'
        }
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      rows: 0,
      filter: {
        branchId: 0,
        page: 1,
        take : 10,
        fromDate: null,
        toDate: null,
        billId: '',
        memberName: '',
        telephone: '',
        singleId: 0
      },
      pageOptions: [
        { value: 5, text: '5 / page' },
        { value: 10, text: '10 / page' },
        { value: 30, text: '30 / page' },
        { value: 50, text: '50 / page' },
        { value: 100, text: '100 / page' }
      ],
      selected: 0,
      isLoading: true,
      branchList: [],
      singleList: [],
      today: null
    };
  },
  validations: {
    filter: {
      fromDate: {
        maxValue(val, { toDate }) {
          return maxDate(val, toDate);
        }
      },
      toDate: {
        minValue(val, { fromDate }) {
          return minDate(val, fromDate);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      branchId: 'getBranchId'
    })
  },
  created: async function () {
    this.today = this.$moment().format('YYYY-MM-DDT00:00:00');
    this.getBranchList();
    this.getSingleList();
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) return;

      this.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.from_date = payload.fromDate;
      payload.to_date = payload.toDate;
      payload.member_name = payload.name;
      payload.branch_id = payload.branchId;
      payload.single_id = payload.singleId;

      this.isLoadingData = true;
      await this.axios
        .post(`${this.$baseUrl}/report/singletreatment/list`, payload)
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
            this.isLoadingData = false;
          }
        });

      this.isBusy = false;
    },
    getBranchList: async function () {
      let list = [{ id: 0, name: 'ทั้งหมด' }];

      await this.axios.get(`${this.$baseUrl}/branch/list`).then(data => {
        if (data.result == 1) {
          data.detail.map(el => {
            el.id, (el.name = `${el.plant_id} ${el.name}`);
          });
          this.branchList = list.concat(data.detail);
        }
      });
    },
    getSingleList: async function () {
      let list = [{ id: 0, name: 'ทั้งหมด' }];

      await this.axios
        .get(`${this.$baseUrl}/report/singletreatment`)
        .then(data => {
          if (data.result == 1) {
            this.singleList = list.concat(data.detail);
          }
        });
    },
    handleSearch(value) {
      this.pagination(1);
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    clearFilter() {
      this.$v.filter.$reset();
      this.filter = {
        branchId: 0,
        page: 1,
        take : 10,
        fromDate: null,
        toDate: null,
        billId: '',
        memberName: '',
        telephone: '',
        singleId: 0
      };
      this.getList();
    },
    changeFromDate(val) {
      this.filter.fromDate = val;
    },
    onChangeToDate(val) {
      this.filter.toDate = val;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .table-main {
  table {
    min-width: max-content;
  }
}
.time {
  color: #afafaf;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.badge {
  font-size: 85%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  max-width: max-content;
  font-weight: 100;
}
.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.icon {
  font-size: 24px;
  color: #000 !important;
}
</style>
