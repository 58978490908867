<template>
  <b-row class="pagination-row">
    <b-col
      cols="12"
      md="4"
      sm="4"
      class="form-inline justify-content-center justify-content-sm-start mb-1 mb-md-0"
    >
      <p class="mb-0 p-gray text-nowrap">
        Showing
        <span id="from-row">{{
          rows == 0 ? 0 : ((filter.page - 1) * filter.take + 1) | numeral('0,0')
        }}</span>
        to
        <span id="to-row">
          {{
            filter.take * filter.page > rows
              ? rows
              : (filter.take * filter.page) | numeral('0,0')
          }}</span
        >
        of <span id="total-row">{{ rows | numeral('0,0') }}</span> entries
      </p>
    </b-col>
    <b-col
      v-if="!hidePage"
      cols="12"
      md="8"
      sm="8"
      class="form-inline justify-content-center justify-content-sm-end page-form"
      style="line-height: 0.2"
    >
      <b-pagination
        v-model="filter.page"
        :total-rows="rows"
        :per-page="filter.take"
        @change="$emit('pagination', $event)"
        first-text="<<"
        prev-text="<"
        next-text=">"
        last-text=">>"
        class="mb-0"
      ></b-pagination>
      <div class="break"></div>
      <br />
      <div class="mt-1 mt-md-0 ml-0 ml-sm-1" v-if="!hideTake">
        <b-form-select
          class="page-take"
          v-model="filter.take"
          @change="$emit('handleChangeTake', $event)"
          :options="pageOptions"
        ></b-form-select>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    limit: {
      require: false,
      type: Number,
      default: 5
    },
    filter: {
      required: true,
      type: Object
    },
    pageOptions: {
      required: false,
      type: Array,
      default: () => {
        return [
          { value: 5, text: '5 / page' },
          { value: 10, text: '10 / page' },
          { value: 30, text: '30 / page' },
          { value: 50, text: '50 / page' },
          { value: 100, text: '100 / page' }
        ];
      }
    },
    handleChangeTake: {
      required: false
    },
    rows: {
      required: false
    },
    pagination: {
      required: false
    },
    hideTake: {
      required: false,
      default: false
    },
    hidePage: {
      required: false,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
.pagination-row {
  span,
  p {
    color: #333 !important;
  }
}
::v-deep .page-item {
  .page-link {
    color: var(--primary-color);
  }
}
::v-deep .page-item.active {
  .page-link,
  .page-link:hover {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: white;
  }
}
::v-deep .page-item.active .page-item {
  font-size: 16px;
}

button.page-link {
  color: var(--primary-color);
}

.pagination-row {
  font-size: 16px;
}

@media screen and (min-width: 667px) and (max-width: 768px) {
  .pagination-row {
    text-align: center;
    div {
      justify-content: center !important;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
@media only screen and (max-width: 820px) and (min-width: 668px) {
  .page-take {
    max-width: 60px;
  }
}

@media (max-width: 667px) {
  .page-form {
    flex-direction: column;
  }
}
</style>
